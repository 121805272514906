<template>
  <div class="content">
    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(rgba(24,62,49,0.2),black)"
      :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
    >
      <section class="col-lg-8 col-sm-12">
        <div class="row" style="margin: 30px">
          <div class="">


            <div
              class="centered"
              style="width: 100%; height: 320px"
              :style="{
              background: `linear-gradient(${this.brandColor}, black)`,
            }"
            >
              <img
                v-lazy="
                 this.bioImage +
                '?w=315&h=200&fit=clamp&auto=format'
              "
                style="
                margin-top: -10px;
                z-index: 12;
                box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);
                border-radius: 0;
              "
                v-bind:alt="artist.title"
                width="315px"
                height="200px"
                class="d-inline-xs d-sm-none"
              />

              <img
                v-lazy="
                this.bioImage +
                '?w=615&h=250&fit=clamp&auto=format'
              "
                style="
                margin-top: -10px;
                z-index: 12;
                box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);
                border-radius: 0;
              "
                v-bind:alt="artist.title"
                width="615px"
                class="d-none d-sm-inline"
                height="250px"
              />
              <h1
                class="tulum-party-title"
                style="
                color: #ffffff !important;
                margin-top: 20px;
                text-transform: uppercase;
              "
              >
                {{ artist.title }}
              </h1>
            </div>

            <p style="margin-top: -30px">{{ artist.metadata.flag }} {{ artist.metadata.headline }} </p>
            <hr class="gradient" style="height: 1px">

            <div class=" text-center col-sm-12 col-md-10 offset-md-1" style="z-index: 1;"
                 v-if="incomingParties.length"
            >

              <div class=" text-left" style=";margin-bottom: 20px;max-width: 350px;margin-left: 1px;z-index: 10"
              >
                <strong class="badge-pill" style="background-color: whitesmoke;margin-bottom: 5px">Next Event</strong>
                <br>
                <br>
                <SmallEventCard :event="incomingParties[0]"
                                class="card-animation-on-hover  animation-transition-general animate-bounce "
                ></SmallEventCard>
              </div>
            </div>
            <p style="font-weight: bold">{{ artist.metadata.genere }}</p>
            <a :href="artist.metadata.soundcloud" target="_blank">Soundcloud</a> |
            <a :href="artist.metadata.instagram" target="_blank">Instagram</a>
            <br>
            <br>

            <div v-html="artist.metadata.short"></div>
            <hr>

            <h4 class="tulum-party-subtitle">About</h4>
            <v-runtime-template :template="artistContent"></v-runtime-template>

            <hr>

            <h4 class="tulum-party-subtitle" v-if="artist.metadata.spotify_id">Top Tracks</h4>
            <p style="margin-top: -15px;font-size: 0.7rem;background-color: #00f5b5;color: black !important;"
               v-if="artist.metadata.spotify_listeners">Monthly Listeners:
              {{ artist.metadata.spotify_listeners.toLocaleString() }}</p>
            <br v-if="artist.metadata.spotify_listeners">
            <iframe style="border-radius:12px;" v-if="artist.metadata.spotify_id"
                    :src="`https://open.spotify.com/embed/artist/${artist.metadata.spotify_id}?utm_source=generator&theme=0`"
                    width="100%" height="200" frameBorder="0" allowfullscreen=""
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy"></iframe>
            <br>
          </div>
          <div class="text-left col-sm-12 col-md-8 offset-md-2 col-xl-10 offset-xl-1">
            <div id="artist-video" class="card" v-if="artist.metadata.videoid">
              <video-card :video-id="artist.metadata.videoid"
                          mtv
                          :title="artist.metadata.videotitle"
                          :caption="artist.metadata.videocaption">
              </video-card>
            </div>
            <div v-if="pastParties.length">
              <hr>
              <div>
                <h4 v-if="incomingParties" class="tulum-party-subtitle">PAST EVENTS</h4>
                <p style="margin-top: -20px;font-size: 11px;color:silver !important;">Tulum, Mexico</p>
              </div>
              <hr>
              <div
                class="card"
                v-for="(event, idx) in pastParties"
                v-bind:event="event"
                :key="event._id"
                :idx="idx"
              >
                <MiniEventCard :event="event" past="true"
                                class="card-animation-on-hover  animation-transition-general animate-bounce "
                ></MiniEventCard>
              </div>
            </div>
            <SmartMenu :links="links" :logo="this.djImg" top-section-name="TALK TO US" cta-text="CONTACT US"
                       :primary-color="primaryColor">

              <div class="card">
                <p>Want to talk with some one? <br> Drop us a line. We are here to assist.</p>
              </div>

            </SmartMenu>
          </div>
        </div>
      </section>
      <aside class="card col-4 col-lg-4 d-none d-lg-inline text-left">
        <SmartMenu :links="links" :logo="this.djImg" top-section-name="TALK TO US" cta-text="CONTACT US"
                   :primary-color="primaryColor">

          <div class="card">
            <p>Want to talk with some one? <br> Drop us a line. We are here to assist.</p>
          </div>

        </SmartMenu>
      </aside>

    </article>
  </div>
</template>

<script>
import moment from "moment";
import SmallEventCard from "@/components/EventCard/SmallEventCard";
import ImgCard from '@/components/Cards/ImgCard'
import VideoCard from '@/components/Cards/VideoCard'
import VRuntimeTemplate from "v-runtime-template";
import SmartMenu from "@/components/SmartMenu/SmartMenu";
import MiniEventCard from "@/components/EventCard/MiniEventCard";

export default {
  name: 'Artist',
  props: ['slug'],
  metaInfo() {
    return {
      title: `${this.artist.title ? ('Artist Bio · ' + this.artist.title + '. ' + this.artist.metadata.headline) : 'TULUM DJ SCHEDULE'}`,
      meta: [
        {
          name: 'description',
          content: `${this.artist.title}`,
        },
        {
          name: 'keywords',
          content: `${this.artist.title}. ${this.artist.metadata.headline}`,
        },
        {
          property: 'og:title',
          content: `${this.artist.title}`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/djs/${this.slug}`,
        },
        {
          property: 'og:description',
          content: `${this.artist.title}. ${this.artist.metadata.caption}`,
        },
        {property: 'og:type', content: 'article'},

      ],
    };
  },
  data: () => {
    return {
      djImg: "https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png",
      table: [],
      primaryColor: '#c67f60'
    };
  },
  created: function () {
    this.$store.dispatch('getArtist', {slug: this.slug});
    this.$store.dispatch('getLinks', {slug: 'tulum-nightlife'});
  },
  computed: {
    artist: {
      get() {
        return this.$store.state.artist;
      },
      links: {
        get() {
          return (
            this.$store.state.links || []
          );
        },
      },
    },
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
    bioImage: {
      get() {
        return this.$store.state.artist.metadata?.main_image?.imgix_url || this.djImg;
      }
    },
    artistContent: {
      get() {
        return '<div>' + this.$store.state.artist.content.replaceAll("<!--", "").replaceAll("-->", "") +

          '</div>'
      },
    },
    incomingParties: {
      get() {
        let parties = this.$store.state.artist.metadata.parties || [];

        function isIncomingParty(x) {
          let partyDateEndOfDay = moment.utc(x.metadata.date).endOf('day');
          let now = moment().startOf('day');
          return partyDateEndOfDay >= now;
        }

        return [...parties]
          .sort((a, b) => {
            return moment.utc(a.metadata.date + ' ' + a.metadata.start_time) >
            moment.utc(b.metadata.date + ' ' + b.metadata.start_time)
              ? 1
              : -1;
          })
          .filter((x) => isIncomingParty(x));
      },
    },
    pastParties: {
      get() {
        let parties = this.$store.state.artist.metadata.parties || [];

        function compareUTCTimes(x) {
          let partyDateEndOfDay = moment.utc(x.metadata.date).endOf('day');
          let now = moment().startOf('day');
          return partyDateEndOfDay < now;
        }

        return [...parties]
          .sort((a, b) => {
            return a.metadata.date < b.metadata.date ? 1 : -1;
          })
          .filter((x) => compareUTCTimes(x));
      },
    },
  },

  components: {
    MiniEventCard,
    SmallEventCard,
    ImgCard,
    VideoCard,
    SmartMenu,
    VRuntimeTemplate
  },
};
</script>

