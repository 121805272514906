<template>
  <div class="card">
    <router-link :to="{name: 'Event', params: {slug: this.event.slug}}">
      <img
        v-lazy="
                          this.event.metadata.main_image.imgix_url +
                          '?q=&w=150&h=100&fit=clamp'
                        "
        style="margin-right: 10px;z-index: 12; box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);border-radius: 0"
        v-bind:alt="this.event.title"
        align="left"
        height="100px"
        width="150px"
      />
      <div
        style="display: inline;vertical-align: top;color: white;position:relative ; " v-if="!this.past">
        <span style="font-weight: 700 !important;">{{ this.event.title.split("|")[0] }}</span>
        <br>
        <p>{{ getDay(this.event.metadata.date + 'T' + this.event.metadata.start_time) }},
          {{ getDay3(event.metadata.date + 'T' + event.metadata.start_time) }}
          {{ getDay2(event.metadata.date + 'T' + event.metadata.start_time) }}. {{
            event.metadata.start_time
          }} Hrs</p>
        <p> {{event.title.split("@")[1]}}</p>
        <small v-if="!this.short">>> INFO|TICKETS|RSVP|+</small>
      </div>
      <div
        style="display: inline;vertical-align: top;color: white;position:relative ; " v-if="this.past">
        <span style="font-weight: 700 !important;">{{ this.event.title.split("|")[0] }}</span>
        <br>
        <p>
          {{ getDay3(event.metadata.date + 'T' + event.metadata.start_time) }}
          {{ getDay2(event.metadata.date + 'T' + event.metadata.start_time) }},
          {{ getYear(event.metadata.date + 'T' + event.metadata.start_time) }}
        </p>
        <p> {{this.event.title.split("@")[1]}}</p>
        <small> >> SEE DETAILS</small>

      </div>
    </router-link>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'SmallEventCard',
  components: {},
  props: ['event', 'idx', 'past', "short"],
  filters: {
    formattedDate: function attachPrettyDate(start_time) {
      const partyDate = start_time;
      const format = 'dddd, MMMM Do YYYY';
      const formatOptions = {
        sameDay: function () {
          return moment(`${partyDate}`, 'YYYY-MM-DD HH:mm', true).isValid()
            ? `[${this.calendar()}] `
            : `[Today ~] ${format}`;
        },
        nextDay: function () {
          return moment(`${partyDate}`, 'YYYY-MM-DD HH:mm', true).isValid()
            ? `[${this.calendar()} / ] ${format}`
            : `[Tomorrow ~] ${format}`;
        },
        nextWeek: format,
        sameElse: format,
      };

      return moment(partyDate).calendar(null, formatOptions);
    },
  },
  data: () => {
    return {};
  },
  computed: {
  },
  methods: {
    getDay: function getYear(date) {
      return moment(date, true).format('ddd')
    },
    getDay2: function getDay2(date) {
      return moment(date, true).format('DD')
    },
    getDay3: function getDay3(date) {
      return moment(date, true).format('MMM')
    },
    getYear: function getMonth(date) {
      return moment(date, true).format('YYYY')
    },

  }
};
</script>

